import httpUtil from '../utils/httpUtil'
import {get} from "@/utils/request";

/**任务管理列表 */

export const queryPage = params => httpUtil.get("/print/task", params);

/**添加任务 */
export const add = params => httpUtil.post("/print/task", params);

/**删除任务 */
export const deleteById = params => httpUtil.delete("/print/task", params);

/** 复制任务 */
export const copyData = params => httpUtil.post("/print/task/copy", params);
/** 任务下发 */
export const distribData = params => httpUtil.post("/print/task/distribution", params);

/** 获取批次号列表 */
export const getTaskNums = params => httpUtil.get("/print/task/getLotNumber", params);

/** 获取设备列表 */
export const getDeviceNums = params => httpUtil.get("/print/device/choose", params);

/**编辑 */
export const update = params => httpUtil.put("/print/task", params);

export const getPost = params => httpUtil.get("/print/task/isPost", params);

export const getHandleInfo = params => httpUtil.get("/print/task/getHandleInfo", params);

/**详情 */
export const taskInfo = params => httpUtil.get("/print/task/taskInfo", params);


