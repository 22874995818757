<template>
  <div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="taskInfoDialogVisible"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        width="80%"
        >
        <commonTable
                :tableData="tableData"
                :loading="loading"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :currentPage="pageNum"
                :total="total"
        >
        <template v-slot:table>
            <el-table-column prop="handle" align="center" label="标识码">
            </el-table-column>
            <el-table-column prop="isPrint" align="center" label="是否打印">
              <template slot-scope="scope">
                {{scope.row.isPrint ? '已打印':'未打印'}}
              </template>
            </el-table-column>
            <el-table-column prop="printTime" align="center" label="打印时间">
              <template slot-scope="scope">
                {{scope.row.printTime|| '---'}}
              </template>
            </el-table-column>

        </template>
        </commonTable>
    </el-dialog>
  </div>
</template>

<script>
    import commonTable from "@/components/common/commonTable";
    import { getHandleInfo } from "@/api/printManage"
    import { printStatus } from '@/utils/constant'

    export default {
        name: "taskHandleDialog",
        components: {
          commonTable
        },
        data() {
            return {
                taskInfoDialogVisible: false,
                tableData: [],
                loading: false,
                pageSize: 10,
                pageNum: 1,
                total: 1,
                type: '',
                taskId: '',
                isPrint: null,
                dialogTitle: '任务详情',
            }
        },

        methods: {
         openDialog(taskId,type,isPrint) {
           this.type=type
           this.pageNum = 1
           this.pageSize = 10,
           this.taskId = taskId,
           this.isPrint = isPrint

           this.taskInfoDialogVisible = true
           this.getTableData()
           let title = '详情'
           this.dialogTitle = title
         },
         closeDialog() {
           this.taskInfoDialogVisible = false
         },
         handleSizeChange(num) {
          this.pageSize = num;
          this.pageNum = 1;
          this.getTableData()
         },
         handleCurrentChange(num){
           this.pageNum = num
           this.getTableData()
         },
         getTableData() {
           console.log('get')
           let params={
             pageNum: this.pageNum,
             pageSize: this.pageSize,
             type: this.type,
             taskId: this.taskId,
               isPrint:this.isPrint
           }
           let v=this

               getHandleInfo(params).then((res) => {
               if (res.code == 200) {
                  v.tableData = res.data.list
                  v.total = res.data.total
                  v.pageNum = res.data.pageNum
                }
             })

         },
         // 获取浏览时长class颜色
          getTimeColor(time){
              let colorArr = ['grey','blue','orange']
              if(time && time < 60){
                  return colorArr[0]
              }else if(time && time >= 60 && time < 600 ) {
                  return colorArr[1]
              }else if(time >= 600) {
                  return colorArr[2]
              }
          },
          getTimeText(time) {
            if(time && time < 600){
              return time + 's'
            }else if(time >= 600) {
              return '600+'
            }else {
              return '-'
            }
          },
        },

        mounted() {


        }
    }
</script>

<style scoped>
.avatar{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 5px;
}
.timeShowTit {
  font-size: 12px;
  background-color: transparent;
  color: #fff;
  border-radius: 4px;
  height: 25px;
  padding: 3px 8px;
  line-height: 25px;
  margin: auto;
}
.timeShowTit.grey{
      background-color: #EAEFFF;
      color: #4A7AF0;
  }
.timeShowTit.blue{
      background-color: #8EABFF;
      color: #fff;
  }
.timeShowTit.orange{
      background-color: #FFAB52;
      color: #fff;
  }
</style>
