<template>
    <div class="view pa24">
        <el-button class="mr10" type="primary" @click="
                    centerDialogVisible=true;
                    ruleForm.taskType = '0';
                    editUpdate = false

                "
        >添加任务
        </el-button
        >
        <commonTable
                :tableData="tableData"
                :loading="loading"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :currentPage="currentPage"
                :total="total"
        >
            <template v-slot:table>
                <!--<el-table-column align="center" prop="taskId"  label="序号"/>-->
                <el-table-column
                        prop="taskName"
                        align="center"
                        label="任务名称"
                        width="100"
                />
                <el-table-column prop="leader" width="80" align="center" label="责任人"/>
                <el-table-column prop="productBatch" width="120" align="center" label="产品批次号">
                    <template slot-scope="scope">
                        {{scope.row.productBatch|| '---'}}
                    </template>
                </el-table-column>
                <el-table-column prop="boxBatch" width="120" align="center" label="箱标批次号">
                    <template slot-scope="scope">
                        {{scope.row.boxBatch|| '---'}}
                    </template>
                </el-table-column>
                <el-table-column prop="boxSpecs" width="120" align="center" label="箱标规格">
                    <template slot-scope="scope">
                        <span style="color: #000000">{{ scope.row.length }}*{{ scope.row.width }}*{{scope.row.height}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="guestsNum" width="80" align="center" label="比例">
                    <template slot-scope="scope">
                        <span style="color: #000000">{{ scope.row.productRatio }}个/{{ scope.row.boxRatio }}箱</span>
                    </template>
                </el-table-column>
                <el-table-column prop="num" align="center" label="产品数">
                    <template slot-scope="scope">
                        <a class="info" @click="getHandleInfo(scope.row.taskId,2,null)">{{ scope.row.num }}</a>
                        /
                        <a class="info" @click="getHandleInfo(scope.row.taskId,2,1)">{{ scope.row.finishNum }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="boxNum" align="center" label="箱标数">
                    <template slot-scope="scope">
                        <a class="info" @click="getHandleInfo(scope.row.taskId,3,null)">{{ scope.row.boxNum }}</a>
                        /
                        <a class="info" @click="getHandleInfo(scope.row.taskId,3,1)">{{ scope.row.boxFinishNum }}</a>
                    </template>
                </el-table-column>
<!--                <el-table-column prop="startTime" align="center" label="开始时间"/>-->
<!--                <el-table-column prop="endTime" align="center" label="结束时间"/>-->
                <el-table-column prop="createTime" align="center" label="创建时间"/>
                <el-table-column prop="postTime" align="center" label="下发时间">
                    <template slot-scope="scope">
                        <span style="color: #000000">{{ scope.row.postTime || '---'}}</span>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="postNum" label="下发次数">
                    <template slot-scope="scope">
                        {{scope.row|postFilter}}
                    </template>
                </el-table-column>
                <el-table-column align="center" width="100" prop="state" label="状态">
                    <template slot-scope="scope">
                        {{ scope.row.status|taskStatusFilter(scope.row.postStatus) }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button
                                type="text"
                                @click="copyTask(scope.row)
              ">复制任务
                        </el-button>
                        <el-button type="text" @click="distribTask(scope.row)
              ">任务下发
                        </el-button>
                        <el-button type="text" @click="
                edit(scope.row, true);
                bEdit = false;
                editUpdate = true
              ">编辑
                        </el-button>
                        <customPopconfirm confirm-button-text="确定" k cancel-button-text="取消"
                                          @confirm="del(scope.row.taskId)" icon="el-icon-info" icon-color="red"
                                          title="确定要删除吗？">
                            <el-button slot="reference" class="ml10" style="color: #fd634e" type="text">删除
                            </el-button>
                        </customPopconfirm>
                        <el-button style="margin-left: 10px;" type="text" @click="toDetails(scope.row.taskId)">详情
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog class="details" title="任务详情" :visible.sync="showDetails" @open="openDialog" @close="close"
                   style="text-align: center;" :close-on-click-modal="false" :modal-append-to-body="false"
                   :destroy-on-close="true" width="30%" center>
            <div>
                <span>任务名称：</span>
                <span>{{pageData.taskName}}</span>
            </div>
            <div>
                <span>责任人：</span>
                <span>{{pageData.leader||''}}</span>
            </div>
            <div>
                <span>任务类型：</span>
                <span>{{pageData.taskType}}</span>
            </div>
            <div>
                <span>产品批次号：</span>
                <span>{{pageData.productBatch || '---'}}</span>
            </div>
            <div>
                <span>箱标批次号：</span>
                <span>{{pageData.boxBatch|| '---'}}</span>
            </div>
            <div>
                <span>产品数/完成数：</span>
                <span>{{pageData.num|| 0}}/{{pageData.finishNum}}</span>
            </div>
            <div>
                <span>箱标数/完成数：</span>
                <span>{{pageData.boxNnum|| 0}}/{{pageData.boxFinishNum}}</span>
            </div>
            <div>
                <span>规格(mm)：</span>
                <span>{{pageData.length}}*{{pageData.width}}*{{pageData.height}}</span>
            </div>
            <div>
                <span>任务状态：</span>
                <span>{{pageData.status }}</span>
            </div>
          <div>
            <span>下发次数：</span>
            <span>第{{pageData.postNum || 0}}次下发</span>
          </div>
            <div>
                <span>任务截止时间：</span>
                <span>{{pageData.endTime}}</span>
            </div>
            <div>
                <span>创建时间：</span>
                <span>{{pageData.createTime}}</span>
            </div>
            <div>
                <span>创建者：</span>
                <span>{{pageData.createBy}}</span>
            </div>
            <div>
                <span>下发时间：</span>
                <span>{{pageData.postTime}}</span>
            </div>
            <div>
                <span>下发者：</span>
                <span>{{pageData.postBy}}</span>
            </div>
          <div>
            <span>下发备注：</span>
            <span>{{pageData.remarks || '---'}}</span>
          </div>
            <div>
                <span>产品码打印机名称：</span>
                <span>{{pageData.deviceName}}-{{pageData.deviceNumber}}</span>
            </div>
            <div>
                <span>箱标码打印机名称：</span>
                <span>{{pageData.boxDeviceName}}-{{pageData.boxDeviceNumber}}</span>
            </div>

        </el-dialog>
        <el-dialog :title="diagTitle" :visible.sync="centerDialogVisible" @open="openDialog" @close="close"
                   :close-on-click-modal="false" :modal-append-to-body="false" :destroy-on-close="true" width="50%"
                   center>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
                <el-form-item label="任务名称" prop="taskName">
                    <el-input v-model="ruleForm.taskName"></el-input>
                </el-form-item>
                <el-form-item label="责任人" prop="leader">
                    <el-input v-model="ruleForm.leader"></el-input>
                </el-form-item>
                <el-form-item label="打印类型" prop="taskType">
                    <el-radio v-model="ruleForm.taskType" label="0" border size="medium">产品码+箱标码</el-radio>
                    <el-radio v-model="ruleForm.taskType" label="1" border size="medium">产品码</el-radio>
                    <el-radio v-model="ruleForm.taskType" label="2" border size="medium">箱标码</el-radio>
                </el-form-item>

                <el-form-item label="产品批次号" prop="productBatch"
                              v-if="ruleForm.taskType === '0'||ruleForm.taskType === '1'">
                    <el-select v-model="ruleForm.productBatch" placeholder="请选择" style="width: 100%">
                        <el-option
                                v-for="item in proOption"
                                :key="item.lotNumber"
                                :label="item.lotNumber + '---' + item.title+'     '+item.count+'个'"
                                :value="item.lotNumber">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="产品数" prop="productRatio" v-if="ruleForm.taskType === '0'">
                    <el-input v-model="ruleForm.productRatio" min="0" type="number" style="width: 47%">
                        <template slot="append">/箱</template>
                    </el-input>
                    <span class="tip">(每箱可放产品的数量)</span>
                </el-form-item>
                <el-form-item label="箱标批次号" prop="boxBatch" v-if="ruleForm.taskType === '0'||ruleForm.taskType === '2'">
                    <el-select v-model="ruleForm.boxBatch" placeholder="请选择" style="width: 100%">
                        <el-option
                                v-for="item in xbOption"
                                :key="item.lotNumber"
                                :label="item.lotNumber + '---' + item.title+'     '+  item.count+'个'"
                                :value="item.lotNumber">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间" prop="startTime">
                    <div class="block">
                        <el-date-picker
                                v-model="ruleForm.startTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                value-format="yyyy-MM-dd hh:mm:ss"
                                align="right"
                                :picker-options="pickerOptions" style="width: 47%">
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item label="结束时间" prop="endTime">
                    <div class="block">
                        <el-date-picker
                                v-model="ruleForm.endTime"
                                type="datetime"
                                placeholder="选择日期时间"
                                value-format="yyyy-MM-dd hh:mm:ss"
                                align="right"
                                :picker-options="pickerOptionsEnd" style="width: 47%">
                        </el-date-picker>
                    </div>
                </el-form-item>
                <el-form-item label="箱子规格" prop="boxSpecs">
                    <el-input v-model="ruleForm.length" min="0" style="width: 30%;margin-right: 10px" type="number"
                              placeholder="长">
                        <template slot="append">mm</template>
                    </el-input>
                    <el-input v-model="ruleForm.width" min="0" style="width: 30%;margin-right: 10px" type="number"
                              placeholder="宽">
                        <template slot="append">mm</template>
                    </el-input>
                    <el-input v-model="ruleForm.height" min="0" style="width: 30%" type="number" placeholder="高">
                        <template slot="append">mm</template>
                    </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="determine">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog
            title="任务下发" 
            :visible.sync="taskDownVisible" 
            @open="openDialog" 
            @close="close"
            :close-on-click-modal="false" 
            :modal-append-to-body="false" 
            :destroy-on-close="true" 
            width="50%"
            center>
            <el-form :model="downForm" ref="downForm" label-width="100px">
                <el-form-item label="选择设备">
                    <el-select v-model="downForm.deviceId" placeholder="请选择" style="width: 80%">
                        <el-option v-for="item in devOption" :key="item.deviceId"
                                   :label="item.deviceName + ' + ' + item.boxDeviceName" :value="item.deviceId">
                        </el-option>
                    </el-select>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
				<el-button @click="taskDownVisible = false">取 消</el-button>
				<el-button type="primary" @click="downTaskClick">确 定</el-button>
			</span>
        </el-dialog>

        <el-dialog
            :modal-append-to-body="false"
            title="提示"
            :visible.sync="TipsInfo"
            :close-on-click-modal="false"
            width="30%"
            center>
            <span v-if="isPostDialogVisible">该任务已经是第{{downForm.postNum+1}}次下发，是否继续</span>
            <span v-if="!isPostDialogVisible">请填写下发信息</span>
            <el-form v-if="!isPostDialogVisible" :model="downForm">
                <el-form-item label="" style="margin-top: 10px">
                    <el-input type="textarea" v-model="downForm.remarks"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
				<el-button @click="noVisible">取 消</el-button>
				<el-button type="primary" @click="isVisible">确 定</el-button>
			</span>
        </el-dialog>


        <taskHandleDialog ref="taskHandleDialog"></taskHandleDialog>
    </div>

</template>

<script>

    import commonTable from "@/components/common/commonTable";
    import {mapState} from 'vuex';
    import customPopconfirm from "@/components/common/customPopconfirm";
    import taskHandleDialog from "./components/taskHandleDialog"
    import {
        queryPage,
        add,
        deleteById,
        update,
        getTaskNums,
        getDeviceNums,
        copyData,
        distribData,
        getPost,
        getHandleInfo,
        taskInfo
    } from "@/api/printManage";
    import {getDataTimeSec} from "@/utils";
    import {taskStatus} from '@/utils/constant'

    let ac;
    export default {
        name: 'taskIndex',
        data() {
            return {
                pageData:{},
                showDetails:false,
                taskHandleData: [],
                taskInfoDialogVisible: false,
                taskDownVisible: false,
                TipsInfo: false,
                isPostDialogVisible: true,
                diagTitle: '添加任务',
                proValue: '',
                proOption: [],
                xbOption: [],
                //
                tableData: [],
                currentPage: 1, //当前页
                pageSize: 10, //显示条数
                loading: false, //表格加载
                total: 0, //总条数
                taskList: [],
                centerDialogVisible: false,
                bEdit: false,
                editUpdate: true,
                isUpdate: true,
                fileList: [],
                userInfo: this.$store.state.loginRoot.userInfo,
                pickerOptions: {
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }],
                    disabledDate: time => {
                        let endDateVal = this.ruleForm.endTime;
                        if (endDateVal) {
                            return time.getTime() > new Date(endDateVal).getTime();
                        }
                    }
                },
                pickerOptionsEnd: {
                    disabledDate: time => {
                        let beginDateVal = this.ruleForm.startTime;
                        if (beginDateVal) {
                            return (
                                time.getTime() <
                                new Date(beginDateVal).getTime()
                            );
                        }
                    }
                },
                ruleForm: {
                    taskName: '', //任务名称
                    productBatch: '', // 产品批次号
                    productRatio: 0, // 产品数
                    boxBatch: '', // 箱标批次号
                    boxRatio: 1, // 箱标数
                    boxSpecs: '',  // 箱子规格
                    leader: '', // 责任人
                    startTime: '',
                    endTime: '',
                    taskType: '0',
                    width: '',
                    length: '',
                    height: ''
                },
                rules: {
                    taskName: [{required: true, message: "请输入任务名称", trigger: "blur"}],
                    productBatch: [{required: true, message: "请选择产品批次号", trigger: "change"}],
                    productRatio: [{required: true, message: "请输入产品数", trigger: "blur"}],
                    boxBatch: [{required: true, message: "请选择箱标批次号", trigger: "change"}],
                    boxRatio: [{required: true, message: "请输入箱标数", trigger: "blur"}],
                    desc: [
                        {required: true, message: '请填写下发信息', trigger: 'blur'}
                    ],
                    deviceId: [
                        {required: true, message: '请选择设备', trigger: ['blur', 'change']}
                    ],
                },
                devOption: [],
                downForm: {
                    taskId: '',
                    deviceId: '', // 设备id
                    postNum: '',
                    postStatus: '',
                    remarks: '',
                },
                downRules: {}
            };
        },
        components: {
            commonTable,
            customPopconfirm,
            taskHandleDialog
        },
        filters: {
            taskStatusFilter(status,postStatus) {
                if (status != null) {
                    if (status == 0) {
                        if (postStatus){
                            return'已下发'
                        } else {
                            return '待开始'
                        }
                    } else if (status == 1) {
                        return '进行中'
                    } else if (status == 2) {
                        return '已完成'
                    } else if (status == 3) {
                        return '过期'
                    }
                }
            },
            postFilter(row) {
                if (!row.postStatus) {
                    return '---'
                }
                return '第' + row.postNum + '次下发';
            }
        },
        computed: {
            ...mapState(['routerArr']),
        },
        watch: {},
        created() {
        },
        mounted() {
            this.queryPage();
            this.getProductBatch();
            this.getBoxBatch();
            this.getdeviceBatch()
        },
        beforeDestroy() {

        },
        methods: {
            toDetails(val) {
                console.log(val)
                this.showDetails = true
                taskInfo({
                    taskId: val
                }).then((res) => {
                    if (res.code == 200) {
                        this.pageData = res.data
                        if (this.pageData.status != null) {
                            if (this.pageData.status == 0) {
                                if (this.pageData.postStatus){
                                    this.pageData.status = '已下发'
                                } else {
                                    this.pageData.status = '待开始'
                                }
                            } else if (this.pageData.status == 1) {
                                this.pageData.status = '进行中'
                            } else if (this.pageData.status == 2) {
                                this.pageData.status = '已完成'
                            } else if (this.pagesData.status == 3) {
                                this.pageData.status = '过期'
                            }
                        }
                    }
                    if (this.pageData.taskType != null) {
                        if (this.pageData.taskType == 0) {
                            this.pageData.taskType = '产品码+箱标码'
                        } else if (this.pageData.taskType == 1) {
                            this.pageData.taskType = '产品码'
                        } else if (this.pageData.taskType == 2) {
                            this.pageData.taskType = '箱标码'
                        }
                    }
                })
            },
            noVisible() {
                this.TipsInfo = false;
                this.isPostDialogVisible = true;
                this.taskDownVisible = false;
                this.downForm = {
                    taskId: '',
                    deviceId: '', // 设备id
                    remarks: '',
                }
            }
            ,
            isVisible() {
                if (this.isPostDialogVisible) {
                    this.isPostDialogVisible = false
                } else {
                    if (!this.downForm.remarks) {
                        return this.$message({
                            message: '请填写下发信息！',
                            type: 'warning'
                        });
                    }
                    this.TipsInfo = false;
                    this.isPostDialogVisible = false;
                    this.taskDownVisible = false
                    //请求任务下发接口
                    console.log(this.downForm)
                    this.distribData();
                }
            }
            ,
            async distribData() {
                this.$refs.downForm.validate(async (valid) => {
                    if (valid) {
                        try {
                            let data = {
                                ...this.downForm
                            };
                            await distribData(data);
                            this.taskDownVisible = false;
                            this.$message({
                                message: "下发成功",
                                type: "success",
                            });
                            this.$message.closeAll();
                            await this.queryPage();
                            this.downForm = {
                                taskId: '',
                                deviceId: '', // 设备id
                                remarks: '',
                            }
                        } catch (error) {
                            this.$message.closeAll();
                            this.$message({
                                message: error.message,
                                type: "error",
                            });
                            this.centerDialogVisible = false;
                        }
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            }
            ,
            async queryPage() {

                let data = {
                    pageSize: this.pageSize,
                    pageNum: this.currentPage
                };

                try {
                    this.loading = true;
                    const result = await
                        queryPage(data);
                    this.loading = false;
                    const {
                        total,
                        list
                    } = result.data;
                    this.tableData = list;
                    this.total = total;
                } catch (error) {
                    this.loading = false;
                }
            }
            ,
            async getProductBatch() {
                const result = await
                    getTaskNums({
                        type: 2
                    })
                console.log('产品批次', result.data);
                this.proOption = result.data
            }
            ,
            async getBoxBatch() {
                const result = await
                    getTaskNums({
                        type: 3
                    })
                console.log('箱标批次', result.data);
                this.xbOption = result.data
            }
            ,
            async getdeviceBatch() {
                let data = {
                    pageSize: 20,
                    pageNum: 1
                };
                const result = await
                    getDeviceNums()
                console.log('设备列表', result.data);
                this.devOption = result.data
            }
            ,
            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.ruleForm.coverImg = "";
            }
            ,

            /**@method 模态框打开时的回调 */
            openDialog() {

            }
            ,
            /**@method 模态框关闭的回调 */
            close() {
                (this.ruleForm = {
                    taskName: '', //任务名称
                    productBatch: '', // 产品批次号
                    productRatio: 0, // 产品数
                    boxBatch: '', // 箱标批次号
                    boxRatio: 0, // 箱标数
                    boxSpecs: '',  // 箱子规格
                    leader: '', // 责任人,
                    taskType: '0',
                    width: '',
                    lenght: '',
                    height: ''
                }),
                    (this.fileList = []);
                this.bEdit = false;
                this.diagTitle = '添加任务'
            }
            ,
            setValue(e) {
                const {
                    item: {value},
                } = e;
                this.ruleForm.address = `${value.city}${value.district}${value.business}`;
            }
            ,
            /**@method 编辑
             * @param {Object} val - 当前点击行的值
             */
            edit(val, b) {
                let data = JSON.parse(JSON.stringify(val));
                this.centerDialogVisible = true;
                this.ruleForm = data;
                console.log(this.ruleForm);
                this.bEdit = b;
                this.diagTitle = '编辑任务'
            }
            ,
            /**@method 添加任务 */
            determine() {
                this.$refs.ruleForm.validate(async (valid) => {
                        if (valid) {
                            try {
                                let data = {
                                    ...this.ruleForm
                                };
                                this.$message({
                                    message: "正在添加",
                                });
                                if (this.editUpdate) {
                                    await update(data);
                                    this.$message({
                                        message: "编辑成功",
                                        type:"success"
                                    });
                                } else {
                                    await add(data);
                                    this.$message({
                                        message: "添加成功",
                                        type:"success"

                                    });
                                    this.queryPage()
                                }
                              this.queryPage();

                              this.centerDialogVisible = false;
                            } catch (error) {
                                this.$message({
                                    message: error.message,
                                    type: "error",
                                });
                            }
                        }
                        else {
                            console.log("error submit!!");
                            return false;
                        }
                    }
                );
            }
            ,
            /**@method 删除
             * @param {String} val - 文章id
             */
            async del(val) {
                try {
                    await deleteById({taskId: val});
                    this.$message({
                        message: "删除成功",
                        type: "success",
                    });
                    await this.queryPage();
                } catch (error) {
                    console.log(error);
                }
            }
            ,
            async copyTask(row) {
                try {
                    await copyData({taskId: row.taskId});
                    this.$message({
                        message: "复制成功",
                        type: "success",
                    });
                    await this.queryPage();
                } catch (error) {
                    console.log(error);
                }
            }
            ,
            async distribTask(row) {
                console.log(row);
                if ((row.taskType === 0 || row.taskType === 1) && !row.productBatch) {
                    this.$message({
                        message: "请选择产品批次号",
                        type: "error",
                    });
                    return
                } else if ((row.taskType === 0 || row.taskType === 2) && !row.boxBatch) {
                    this.$message({
                        message: "请选择箱标批次号",
                        type: "error",
                    });
                    return
                }
                this.downForm.taskId = row.taskId
                this.downForm.postNum = row.postNum
                this.downForm.postStatus = row.postStatus
                if (row.postStatus) {
                    //请求获取下发次数
                    try {
                        const result = await getPost({taskId: row.taskId});
                        console.log(result.data)
                        this.downForm.postNum = result.data

                    } catch (error) {
                        console.log(error);
                    }
                }
                this.taskDownVisible = true
            }
            ,
            async downTaskClick() {
                if (!this.downForm.deviceId) {
                    return this.$message({
                        message: '请选择设备！',
                        type: 'warning'
                    });
                }
                if (this.downForm.postNum >= 1) {
                    //如果已下发，弹提示框
                    this.TipsInfo = true
                } else {
                    this.distribData()
                }
            }
            ,
            handleSizeChange(val) {
                this.pageSize = val;
                this.queryPage();
            }
            ,
            handleCurrentChange(val) {
                this.currentPage = val;
                this.queryPage();
            }
            ,
            getHandleInfo(taskId, type, isPrint) {
                this.$refs.taskHandleDialog.openDialog(taskId, type, isPrint)
            },

        },


    }
    ;
</script>

<style lang="scss" scoped>
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 78px;
        height: 78px;
        line-height: 180px;
        text-align: center;
    }

    /deep/ .avatar-coverImg .el-upload--text {
        width: 100px;
        height: 100px;
    }

    /deep/ .avatar-coverImg .avatar-uploader-icon {
        line-height: 100px;
    }

    .select-goods-list {
        margin-bottom: 20px;
        border-radius: 40px;
        box-sizing: border-box;
        padding: 0 20px;
        background: #ffffff;
        transition: all 0.4s;
    }

    .select-goods-list:hover {
        background: #f5f5f6;
        transition: all 0.4s;
        cursor: pointer;
    }


    /deep/ .disabled .el-upload--picture-card {
        display: none;
    }

    .info {
        cursor: pointer;
        color: #113DEE;
    }

/deep/ .disabled .el-upload--picture-card {
display: none;
}
.info{
cursor: pointer;
color: blue;
}

/deep/ .disabled .el-upload--picture-card {
display: none;
}


/deep/ .details .el-dialog__body {
text-align: center;
font-size: 16px
}

/deep/ .details .el-dialog__body div {
line-height: 32px;
}

/deep/ .details .el-dialog__body div span:first-child {
width: 180px;
display: inline-block;
text-align: right;
}

/deep/ .details .el-dialog__body div span:last-child {
width: 180px;
display: inline-block;
text-align: left;
}
.tip{
    color: #f00;
    padding-left: 20px;
}
</style>
