let baseImgUrl='https://mzmpic.oss-cn-beijing.aliyuncs.com/'
const taskStatus = { 0: '未开始', 1: '进行中', 2: '已完成' }
const deviceStatus = { 1: '产品打印机', 2: '箱标打印机',3:'扫码枪' }

//打款状态
export  let withdrawStatusMap={0:"未到打款时间",1:"已打款",2:"已驳回",3:"待审核",4:"打款失败"}

export  let orderStatusMap={
    0: "-",
    1: "待付款",
    2: "待发货",
    3: "待收货",
    4: "已完成",
    5: "取消订单",
    6: "退款成功",
}

export  let commissionMap={0:'进行中',1:'已完成',2:'分销失败'}

export { baseImgUrl, taskStatus, deviceStatus }
